var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-col",
    { staticClass: "mb-4", attrs: { xl: "2", md: "4", sm: "6", xs: "12" } },
    [
      _c("div", {
        class: [_vm.classObj, _vm.color],
        style: { paddingTop: "75%" },
      }),
      _vm._t("default"),
      _c("color-view"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }