var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c(
        "b-card",
        { attrs: { "header-tag": "header" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("i", { staticClass: "icon-drop" }),
            _vm._v(" Theme colors"),
          ]),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                [
                  _c("color-theme", { attrs: { color: "bg-primary" } }, [
                    _c("h6", [_vm._v("Brand Primary Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-secondary" } }, [
                    _c("h6", [_vm._v("Brand Secondary Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-success" } }, [
                    _c("h6", [_vm._v("Brand Success Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-danger" } }, [
                    _c("h6", [_vm._v("Brand Danger Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-warning" } }, [
                    _c("h6", [_vm._v("Brand Warning Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-info" } }, [
                    _c("h6", [_vm._v("Brand Info Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-light" } }, [
                    _c("h6", [_vm._v("Brand Light Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-dark" } }, [
                    _c("h6", [_vm._v("Brand Dark Color")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { "header-tag": "header" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("i", { staticClass: "icon-drop" }),
            _vm._v(" Grays"),
          ]),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                [
                  _c("color-theme", { attrs: { color: "bg-gray-100" } }, [
                    _c("h6", [_vm._v("Brand 100 Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-gray-200" } }, [
                    _c("h6", [_vm._v("Brand 200 Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-gray-300" } }, [
                    _c("h6", [_vm._v("Brand 300 Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-gray-400" } }, [
                    _c("h6", [_vm._v("Brand 400 Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-gray-500" } }, [
                    _c("h6", [_vm._v("Brand 500 Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-gray-600" } }, [
                    _c("h6", [_vm._v("Brand 600 Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-gray-700" } }, [
                    _c("h6", [_vm._v("Brand 700 Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-gray-800" } }, [
                    _c("h6", [_vm._v("Brand 800 Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-gray-900" } }, [
                    _c("h6", [_vm._v("Brand 900 Color")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { "header-tag": "header" } },
        [
          _c("div", { attrs: { slot: "header" }, slot: "header" }, [
            _c("i", { staticClass: "icon-drop" }),
            _vm._v(" Additional colors"),
          ]),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                [
                  _c("color-theme", { attrs: { color: "bg-blue" } }, [
                    _c("h6", [_vm._v("Brand Blue Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-indigo" } }, [
                    _c("h6", [_vm._v("Brand Indigo Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-purple" } }, [
                    _c("h6", [_vm._v("Brand Purple Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-pink" } }, [
                    _c("h6", [_vm._v("Brand Pink Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-red" } }, [
                    _c("h6", [_vm._v("Brand Red Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-orange" } }, [
                    _c("h6", [_vm._v("Brand Orange Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-yellow" } }, [
                    _c("h6", [_vm._v("Brand Yellow Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-green" } }, [
                    _c("h6", [_vm._v("Brand Green Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-teal" } }, [
                    _c("h6", [_vm._v("Brand Teal Color")]),
                  ]),
                  _c("color-theme", { attrs: { color: "bg-cyan" } }, [
                    _c("h6", [_vm._v("Brand Cyan Color")]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }